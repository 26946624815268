@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.info {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 40px;
  gap: 40px;
  
  @media (max-width: 810px) {
    flex-direction: column;
    gap: 20px;
  }

  &__column {
    display: flex;
    flex-direction: column;
    gap: 2px;
    flex: 1;

    &:first-child {
      text-align: left;

      @media (max-width: 810px) {
        order: 2;
      }
    }
  
    &:last-child {
      text-align: right;

      @media (max-width: 810px) {
        text-align: left;
        order: 1;
      }
    }
  }

  &__text {
    @include customizableText(500, 13px, 12px);
    color: $gray-400;
    margin: 0;

    @include mobile {
      @include customizableText(500, 16px, 16px);
    }
  }

}