@import "../../styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "../../styles/scss/mixins/typography";

.root {
  position: relative;
  z-index: 999;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: white;
  z-index: 999;

  &--overlay-active {
    position: relative;
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.2);
      z-index: -1;
      pointer-events: none;
    }
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 23px 0px;
  max-width: 1260px;
  margin: 0 auto;
  transition: padding 0.3s ease;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 1px;
    background-color: $gray-300;
  }

  &--overlay-active::after {
    background-color: $gray-400;
  }

  &--scrolled {
    padding: 11px 0px;

    @media (max-width: 1023px) {
      padding: 23px 20px;
    }
  }

  @media (max-width: 1023px) {
    padding: 21px 30px 21px 30px;

    &::after {
      width: 100%;
      left: 0;
    }
  }

  @media (max-width: 359px) {
    padding: 24px 30px;
  }

  @media (max-width: 1270px) and (min-width: 1024px) {
    padding: 23px 20px;

    &::after {
      width: calc(100% - 40px);
      left: 20px;
    }
  }

  &--open::after {
    width: calc(100% - 60px);
    left: 30px;
  }
}

.wrapper-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.wrapper-bottom {
  width: 100%;
  margin-top: 0;
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition: opacity 0.5s ease, max-height 0.5s ease;

  @media (min-width: 501px) {
    display: none;
  }

  &--visible {
    opacity: 1;
    max-height: 50px;
    margin-top: 20px;
  }
}

.logo {
  height: 22px;
  width: 122px;

  @media (min-width: 360px) {
    width: 165px;
    height: 29px;
  }

  @media (min-width: 501px) {
    width: 178px;
    height: 32px;
  }

  @include desktop {
    height: 44px;
    width: 245px;
  }
}

.logo-wrapper-tablet {
  display: none;
}

.catalog-titles {
  position: fixed;
  top: 94px;
  background-color: white;
  z-index: 10;
  transition: transform .6s ease, opacity .6s ease;
  width: 100%;
  opacity: 0;
  will-change: transform, opacity;

  @include desktop {
    opacity: 1;
    transform: translateY(0);
  }

  @media (max-width: 1023px) {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 73px;
    left: 0;
    height: 614px;
    background-color: $white;
    transform: translateY(-100%);
    opacity: 0;
    z-index: 333;
    border-radius: 0 0 30px 30px;
    overflow-y: hidden; 
  }

  @media (max-width: 500px) and (max-height: 780px) {
    max-height: calc(100dvh - 73px);
    overflow-y: auto;
  }

  @media (max-width: 500px) {
    height: fit-content;
  }

  @media (max-width: 385px) {
    height: fit-content;
  }
  
  &--hidden {
    transform: translateY(-100%);
    opacity: 0;
  }

  &--open {
    transform: translateY(0);
    opacity: 1;
  }
}

.menu-icon-wrapper {
  @media (max-width: 768px) {
    width: auto;
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 332;
  cursor: pointer;

  @media (min-width: 1024px) {
    display: none;
  }
}
