@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.root {
  max-width: 1270px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 0 5px;

  & > :first-child {
    margin-top: -30px;
  }
}

.top {
  display: flex;
  flex-direction: column;
  gap: 20px;
  
  @include tablet {
    flex-direction: row;
    gap: 30px;
  }
}

.divider {
  width: 100%;
  height: 2px;
  background-color: $gray-200;
}
