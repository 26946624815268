@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.social {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 34px 30px;
  background-color: $gray-200;
  border-radius: 20px;

  @media (max-width: 950px) {
    justify-content: center;
  }

  &__title {
    @include customizableText(600, 20px, 20px);
    color: $primary-500;
    margin-bottom: 20px;
  }

  &__links {
    display: flex;
    gap: 50px;

    @include tablet {
      gap: 18px;
    }
  }

  &__link {
    width: 48px;
    height: 48px;
    background-color: $primary-500;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 16px;
    transition: background-color 0.3s;

    &:hover {
      background-color: lighten($primary-500, 10%);
    }
  }
}