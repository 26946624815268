@import "../../../styles/scss/variables";
@import "../../../styles/scss/mixins/typography";

.root {
  display: flex;
  justify-content: center;
  align-items: center;
}

.list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 30px;

  @media (max-width: 330px) {
    gap: 15px;
  }
}

.item {
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  @media (min-width: 360px) {
    width: 32px;
    height: 32px;
  }

  @media (min-width: 501px) {
    width: 24px;
    height: 24px;
  }

  &__button, &__link {
    background: none;
    border: none;
    padding: 0;
    margin: 0;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  &__toggler {
    display: none;

    @media (max-width: 500px) {
      display: flex;
    }
  }
}

.notification {
  position: absolute;
  top: -2px;
  right: -3px;
  width: 10px;
  height: 10px;
  background-color: $danger-500;
  border: 2px solid white;
  border-radius: 50%;

  &--red {
    background-color: $danger-500;
  }

  &--gray {
    background-color: $gray-500;
  }
}

.user-icon {
  @media (max-width: 500px) {
    display: none;
  }
}

.heart-icon {
  @media (max-width: 500px) {
    display: none;
  }
}

.phone-icon {
  display: none;

  @media (min-width: 1024px) {
    display: flex;
  }
}

.menu-icon-wrapper {
  display: none;

  @media (max-width: 500px) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
  }
}