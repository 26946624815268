@import "/src/styles/scss/variables";
@import "/src/styles/scss/mixins/typography";
@import "/src/styles/scss/breakpoints";

.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  height: 433px;
  gap: 20px;
  border-radius: 30px;
  padding: 30px;
}

.head-title {
  align-self: center; 
  @include customizableText(600, 18px, 18px);
  color: $primary-500;

  @include mobile {
    @include customizableText(600, 20px, 20px);
  }
}

.main-title {
  @include customizableText(600, 14px, 14px);
  color: $gray-400;

  @include mobile {
    @include customizableText(600, 16px, 16px);
  }
}

.subtitle {
  @include customizableText(500, 14px, 14px);
  color: $primary-500;

  @include mobile {
    @include customizableText(500, 18px, 18px);
  }
}

.divider {
  width: 100%;
  height: 1px;
  background-color: $gray-200;
}

.phones-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  gap: 20px;
}

.links-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  gap: 20px;
}

.link {
  width: 48px;
  height: 48px;
  background-color: $primary-500;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  transition: background-color 0.3s;

  &:hover {
    background-color: lighten($primary-500, 10%);
  }
}

.close-cross {
  position: absolute;
  width: 24px;
  height: 24px;
  top: 26px;
  right: 26px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
}
