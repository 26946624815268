@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.subscription {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 30px;
  border-radius: 20px;
  background: $gradient-primary;
  gap: 30px;

  @media (min-width: 500px) {
    flex-direction: row;
  }

  &__title {
    @include customizableText(700, 26px, 26px);
    color: $primary-25;
    max-width: 324px;
  }

  &__row {
    display: flex;
    flex-direction: column; 
    gap: 26px;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 26px;

    @media (min-width: 950px) {
      flex-direction: row;
      align-items: center;
      gap: 20px;
    }
  }
}

.custom-label {
  @include customizableText(600, 12px, 12px);
  color: $gray-400;
}