@import "/src/styles/scss/variables";
@import "/src/styles/scss/mixins/typography";
@import "/src/styles/scss/breakpoints";

.root {
  display: flex;
  flex-direction: column;

  font-size: 14px;
  font-weight: 300;
  font-family: Jost;

  text-align: start;

  padding: 0 20px;
  margin-bottom: 100px;

  @include desktop {
    padding: 0 100px;
    margin-bottom: 180px;
  }

  &__text {
    @include customizableText(400, 14px, 21px);
    padding: 5px 0;
    margin-bottom: 10px;

    @include tablet {
      @include customizableText(400, 16px, 24px);
    }

    @include desktop {
      @include customizableText(400, 18px, 29px);
      text-align: justify;

      margin-bottom: 20px;
    }
  }

  &__link {
    color: $primary;

    @include customizableText(400, 14px, 21px);
    padding: 5px 0;

    @include tablet {
      @include customizableText(400, 16px, 24px);
    }

    @include desktop {
      @include customizableText(400, 18px, 29px);
      text-align: justify;

      margin-bottom: 20px;
    }
  }
}

.seo {
  position: absolute;
  left: -9999px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.custom-title {
  @include customizableText(500, 24px, 30px);

  margin-top: 100px;
  margin-bottom: 20px;

  @include mobile {
    @include customizableText(500, 28px, 30px);
  }

  @include tablet {
    @include customizableText(500, 30px, 38px);
  }

  @include desktop {
    @include customizableText(500, 32px, 38px);

    margin-top: 200px;
    margin-bottom: 73px;
  }
}
