@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.list {
  padding: 0 5px;
  
  @media (max-width: 809px) {
    padding: 0 100px;
  }

  @media (max-width: 630px) {
    padding: 0 30px;
  }

  @media (max-width: 500px) {
    padding: 0 5px;
  }

  @media (min-width: 950px) {
    padding: 0 50px;
  }

  &__container {
    display: flex;
    justify-content: space-between;
    gap: 10px;
  }

  &__column {
    display: flex;
    flex-direction: column;
    gap: 10px;

    &--hidden {
      display: none;
    }

    @include mobile {
      gap: 20px;
    }

    @media (min-width: 810px) {
      &--hidden {
        display: flex;
      }
    }
  }

  &__title {
    @include customizableText(700, 20px, 18px);
    color: $primary-500;

    @include mobile {
      @include customizableText(700, 24px, 24px);
    }
  }

  &__links {
    display: flex;
    flex-direction: column;
    gap: 10px;
    list-style: none;
    padding: 0;
    margin: 0;

    @include mobile {
      gap: 20px;
    }
  }

  &__link {
    @include customizableText(500, 13px, 12px);
    color: $gray-500;
    text-decoration: none;

    @include mobile {
      @include customizableText(500, 16px, 16px);
    }

    &:hover {
      color: $primary-500;
    }
  }
}