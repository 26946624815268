@import "/src/styles/scss/variables";
@import "/src/styles/scss/mixins/typography";

.checkbox {
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 10px;

  &__indicator {
    width: 22px;
    height: 22px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
  }

  &__label {
    @include commonText(400);
  }

  input:disabled + &__indicator {
    cursor: not-allowed;
    opacity: 0.5;
  }
  
  input:disabled ~ .checkbox__label {
    cursor: not-allowed;
    opacity: 0.5;
  }
}


.default {
  background-color: $primary-25;
}

.check {
  background-color: $primary-500;
}

.chosen {
  background-color: $primary-500;
}

.checked .checkbox__indicator {
  background-color: $primary-500;
}