@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.root {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0 5px;

  max-width: 1537px;
  width: 100%;

  margin: 87px auto 85px;

  @include mobile {
    padding: 0 10px;
  }

  @include desktop {
    margin: 180px auto;
  }
}

.title {
  @include customizableText(700, 32px, 30px);
  color: $primary-500;
  margin: 40px 0 40px;
  align-self: center;

  @include desktop {
    @include customizableText(700, 40px, 49px);
  }
}

.container {
  display: flex;
  flex-direction: column;
  gap: 20px;

  @include tablet {
    gap: 30px;
  }

  @include desktop {
    gap: 40px;
  }
}

.top-container {
  display: flex;
  flex-wrap: nowrap;
  gap: 20px;
  justify-content: center;

  @include tablet {
    gap: 30px;
  }

  @include desktop {
    gap: 40px;
  }
}

.favorites-preview {
  width: 100%;
  max-width: 900px;
  min-width: 290px;
  min-height: 406px;

  @media (min-width: 700px) {
    min-height: 474px;
  }
}

.orders-preview {
  display: flex;
  flex-direction: column;
  width: 320px;
  min-height: 436px;
  flex-shrink: 0;

  @media (max-width: 340px) {
    width: 100%;
    min-width: 290px;
  }

  @media (min-width: 700px) {
    min-height: 474px;
  }
}

@media (max-width: 700px) {
  .container {
    gap: 20px;
  }

  .top-container {
    gap: 20px;
    flex-direction: column;
  }

  .favorites-preview {
    order: 2;
  }

  .orders-preview {
    order: 1;
    width: 100%;
    max-width: 680px;
  }
}
