@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.root {
  display: flex;
  justify-content: center;
  max-width: 1260px;
  margin: 0 auto;
  padding: 20px 0;

  @media (max-width: 1270px) {
    padding: 20px 20px;

    &::before {
      left: 20px;
      right: 20px;
    }
  }

  @media (max-width: 1023px) {
    padding: 30px 30px 30px;
  }
}

.list {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0;
  list-style: none;
  position: relative;

  @media (max-width: 1023px) {
    flex-direction: column;
    align-items: flex-end;
    gap: 30px;
    padding-top: 73px;
    padding-bottom: 30px;
  }

  @media (max-width: 500px) and (min-width: 100px) {
    padding-bottom: 0;
  }

  &__item {
    display: flex;
    align-items: center;
    flex-direction: row;
    list-style-type: none;

    @media (max-width: 1023px) {
      justify-content: flex-end;
    }

    a,
    button {
      text-decoration: none;
      background: none;
      border: none;
      padding: 0;
      margin: 0;
      cursor: pointer;
      color: $primary-500;
      @include customizableText(500, 18px, 22px);
    }

    &--sale {
      padding: 6px 10px;
      border-radius: 20px;
      background-color: $secondary-25;
      position: relative;

      a,
      button {
        color: $secondary-500;
        @include customizableText(700, 18px, 22px);
      }
    }

    &__city-select {
      display: none;

      @media (max-width: 1023px) {
        display: block;
        margin-top: 0;
      }
    }

    &__profile {
      display: none;

      @media (max-width: 500px) {
        display: flex;
      }
    }

    &__heart {
      display: none;

      @media (max-width: 500px) {
        display: flex;
      }
    }

    &__contacts {
      display: none;

      @media (max-width: 1023px) {
        display: block;
        margin-top: 0;
      }
    }
  }
}

.contacts-button {
  display: flex;
  align-items: center;
  gap: 10px;
  background: none;
  border: none;
  cursor: pointer;
  color: $primary-500;
  @include customizableText(500, 18px, 22px);
}

.profile-button {
  display: none;
  align-items: center;
  gap: 10px;
  background: none;
  border: none;
  cursor: pointer;
  color: $primary-500;
  @include customizableText(500, 18px, 22px);

  @media (max-width: 500px) {
    display: flex;
  }
}

.heart-link {
  display: none;
  align-items: center;
  gap: 10px;
  background: none;
  border: none;
  cursor: pointer;
  color: $primary-500;
  @include customizableText(500, 18px, 22px);

  @media (max-width: 500px) {
    display: flex;
  }
}

.divider {
  width: 100%;
  height: 1px;
  background-color: $gray-300;
}
