@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.root {
  max-width: 1500px;
  width: 100%;

  margin: 0 auto;
  padding: 100px 20px 60px;

  @include desktop {
    padding: 207px 20px;
  }
}

.breadcrumbs {
  display: flex;
  overflow-x: auto;

  margin: 0 -16px 30px;
  padding: 0 16px;

  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;

    margin-bottom: 30px;
    padding: 0;
  }
}