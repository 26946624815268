@import "../../../styles/scss/variables";
@import "../../../styles/scss/mixins/typography";

.root {
  position: fixed;
  left: 0;
  right: 0;
  bottom: calc(50% - 30px);

  width: calc(100% - 60px);
  height: calc(100vh - 102px);

  margin: auto;
  padding: 0px;

  transform: translateY(50%);
  background-color: $white;
  overflow: hidden;
  box-shadow:
    -5px -4px 20px rgba(0, 0, 0, 0.25),
    4px 4px 20px rgba(0, 0, 0, 0.25);

  z-index: 999;
}

.content {
  height: calc(100% - 40px);
}

.head {
  position: relative;
  height: 40px;
  width: 100%;
}

.button {
  position: absolute;
  top: 0;
  right: 0;

  &--img {
    padding: 10px;
    width: 40px;
    height: 40px;
  }
}
