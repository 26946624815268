@import "/src/styles/scss/variables";
@import "/src/styles/scss/mixins/typography";
@import "/src/styles/scss/breakpoints";

.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  max-width: 1537px;
  width: 100%;

  margin: 0 auto;
  padding: 20px 10px;
  padding-top: 120px;
  padding-bottom: 100px;

  @media (min-width: 451px) {
    padding-top: 137px;
    padding-bottom: 170px;
  }

  @include desktop {
    padding: 207px 10px;
    padding-bottom: 170px;
  }
}

.title-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;

  width: 100%;
}

.link {
  position: absolute;
  top: -100%;
  left: 0;
  margin-left: 10px;

  @include customizableText(500, 14px, 17px);
  color: $textLightGrey;

  transform: translateY(-50%);
  @include desktop {
    top: 50%;
  }
}

.data {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: $bleachedSilk;

  margin-top: 50px;
  border-radius: 10px;
  padding: 20px;

  @include tablet {
    padding: 23px;
    gap: 20px;
  }

  @include desktop {
    display: grid;
    grid-template-columns: minmax(200px, 599px) minmax(350px, auto);
    align-items: flex-start;
    margin-top: 100px;
  }
}
