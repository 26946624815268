@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.page {
  display: flex;
  max-width: 1260px;
  margin: 0 auto;
  margin-top: 80px;
  padding: 0 5px;

  @include mobile {
    padding: 0 10px;
  }

  @include tablet {
    margin-top: 107px;
  }

  @include desktop {
    margin-top: 207px;
  }
}

.content {
  display: flex;
  flex-direction: column;
  max-width: 980px;
  width: 100%;

  &__title {
    @include customizableText(700, 30px, 39px);
    margin: 10px 0 40px 0;

    @include tablet {
      margin: 0 0 40px 0;
      @include customizableText(700, 40px, 49px);
    }
  }

  &__navigation {
    display: none;
    margin-bottom: 20px;
    margin-top: 10px;

    @media (max-width: 941px) {
      display: block;
    }
  }
}

.navigation {
  width: 230px;
  margin-right: 50px;

  @media (max-width: 941px) {
    display: none;
  }
}