@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  max-width: 1537px;
  width: 100%;

  margin: 0 auto;
  padding: 100px 20px 10px;

  @media (min-width: 451px) {
    padding: 137px 10px;
  }

  @include desktop {
    padding: 207px 10px;
  }
}

.products {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: $bleachedSilk;
  gap: 30px;
  margin-top: 40px;
  border-radius: 10px;
  padding: 20px;

  @include tablet {
    padding: 23px;
    gap: 20px;
  }

  @include desktop {
    display: grid;
    grid-template-columns: minmax(470px, 958px) 1fr;
  }
}
