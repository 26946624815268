@import "/src/styles/scss/variables";
@import "/src/styles/scss/mixins/typography";
@import "/src/styles/scss/breakpoints";

.root {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  width: 100%;
  max-width: 1920px;
  text-align: center;
  margin-bottom: 20px;
  margin-top: 72px;
  max-height: 894px;

  @include desktop {
    margin-top: 168px;
  }

  &__loading {
    height: 46.56vw;
  }
}
