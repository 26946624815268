@import "../../../styles/scss/variables";
@import "../../../styles/scss/mixins/typography";

.root {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  max-width: 516px;
  width: 100%;

  border-radius: 10px;
  padding: 22px 44px;

  box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.25);

  @media (min-width: 380px) {
    padding: 32px 84px;
  }
}