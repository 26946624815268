@import "../../styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "../../styles/scss/mixins/typography";

.city-select {
  display: flex;
  align-items: center;
  gap: 10px;

  &__info_button {
    background: none;
    border: none;
    cursor: pointer;
    color: $primary-500;
    @include customizableText(500, 18px, 22px);
  }
}

.cities-suggestion-modal {
  position: absolute;
  top: 80px;
  left: 0px;
  width: 260px;
  height: 180px;

  border-radius: 10px;
  padding: 26px 22px;

  box-shadow:
    -5px -4px 20px rgba(0, 0, 0, 0.25),
    4px 4px 20px rgba(0, 0, 0, 0.25);

  z-index: 10;

  &--shadow-disabled {
    box-shadow: none;
  }

  @media (max-width: 1023px) {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    max-width: 371px;
    height: auto;
    max-height: 90%;
    overflow-y: auto;
  }

  @media (max-width: 1023px) and (max-height: 780px) {
    top: 65%;
    transform: translate(-50%, -65%);
  }

  @include desktop {
    width: 371px;
    height: 140px;
  }
}

.marker-icon {
  color: #191B38;

  @media (max-width: 1023px) {
    color: $secondary-500;
  }
}
