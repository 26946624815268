@import "/src/styles/scss/variables";
@import "/src/styles/scss/mixins/typography";
@import "/src/styles/scss/breakpoints";

.root {
  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.contacts {
  position: absolute;
  top: 50px;
  right: 100%;
  transform: translateX(40%);
  width: 340px;
  opacity: 1;
  background-color: $white;
  box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.2);
  transition: opacity 200ms ease;
  z-index: 1000;

  &--custom {
    position: fixed;
    top: 63%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% - 20px);
    z-index: 1100;
    pointer-events: auto;
    border-radius: 30px;

    @media (max-width: 500px) and (min-height: 600px) and (max-height: 780px) {
      top: 50%;
      max-height: 90%;
      overflow-y: auto;
    }
  
    @media (max-width: 500px) and (min-height: 781px) {
      top: 66%;
    }

    &.authorized {
      @media (max-width: 500px) and (min-height: 600px) and (max-height: 780px) {
        top: 50%;
        max-height: 90%;
        overflow-y: auto;
      }
    
      @media (max-width: 500px) and (min-height: 781px) {
        top: 68.5%;
      }
    }
  }

  &-enter {
    opacity: 0;
  }

  &-enter-active {
    opacity: 1;
  }

  &-exit {
    opacity: 1;
  }

  &-exit-active {
    opacity: 0;
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100dvw;
  height: 100dvh;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 1000;
  pointer-events: none;

  @media (max-width: 500px) and (min-height: 600px) and (max-height: 780px) {
    height: calc(100dvh + 200px);
  }

  @media (max-width: 500px) and (min-height: 781px) {
    height: 100dvh;
  }

}
