@import "/src/styles/scss/variables";
@import "/src/styles/scss/mixins/typography";
@import "/src/styles/scss/breakpoints";

.custom-title {
  @include customizableText(500, 24px, 30px);

  margin-top: 100px;
  margin-bottom: 20px;

  @include mobile {
    @include customizableText(500, 28px, 30px);
  }

  @include tablet {
    @include customizableText(500, 30px, 38px);
  }

  @include desktop {
    @include customizableText(500, 32px, 38px);

    margin-top: 200px;
    margin-bottom: 73px;
  }
}

.root {
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  margin-bottom: 100px;

  @include desktop {
    padding: 0 100px;
  }
}

.info {
  display: flex;
  flex-direction: column;

  @include customizableText(400, 14px, 21px);

  margin-bottom: 10px;

  @include tablet {
    @include customizableText(400, 16px, 24px);
  }

  @include desktop {
    @include customizableText(400, 18px, 29px);

    margin-bottom: 20px;
  }

  &:first-child {
    > p {
      text-align: center;
    }
    > p:last-child {
      margin-bottom: 20px;

      @include tablet {
        margin-bottom: 40px;
      }

      @include desktop {
        margin-bottom: 73px;
      }
    }
    > p {
      > span {
        color: $primary;
      }
      align-self: center;
      font-weight: 500;

      margin: 0;
      color: $textBlack;
    }
  }
  > p:last-child {
    margin-bottom: 73px;
  }
  &:nth-child(2) {
    > p:last-child {
      margin-bottom: 20px;

      @include tablet {
        margin-bottom: 40px;
      }

      @include desktop {
        margin-bottom: 111px;
      }
    }
  }
  > :first-child {
    align-self: flex-start;
    color: $primary;
    font-weight: 500;
    margin-bottom: 25px;
  }
  &:last-child {
    > p:last-child {
      align-self: center;
      color: $primary;
      font-weight: 500;

      margin: 0;
      margin-top: 73px;
    }
  }
}

.title {
  @include customizableText(500, 18px, 30px);
  align-self: center;
  padding: 10px 0;
  margin-bottom: 20px;
  color: $textBlack;

  @include tablet {
    @include customizableText(500, 20px, 30px);
  }

  @include desktop {
    @include customizableText(500, 24px, 28px);
    margin-bottom: 25px;
  }
}

.item_link {
  color: $primary;
}

.seo {
  position: absolute;
  left: -9999px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}