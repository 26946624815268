@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.input-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.label {
  color: $primary-500;
  margin-bottom: 10px;
  @include customizableText(500, 16px, 20px);
}

.input-container {
  display: inline-flex;
  align-items: center;
  background-color: $gray-100;
  height: 50px;
  padding: 0 15px;
  border-radius: 16px;
  color: $gray-400;

  &--error {
    border-color: $danger-500;
  }

  &--disabled {
    border: none;
    color: $gray-300;
    border: 1px solid $gray-300;
    background-color: $white;
  }

  &:focus-within {
    box-shadow: 0 0 0 6px rgba(242, 244, 247, 0.5);
    -webkit-box-shadow: 0 0 0 6px rgba(242, 244, 247, 0.5);
    -moz-box-shadow: 0 0 0 6px rgba(242, 244, 247, 0.5);
  }
}

.icon-start {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  width: 16px;
  height: 16px;
}

.icon-hidden {
  visibility: hidden;
}

.input {
  border: none;
  outline: none;
  background: transparent;
  color: $primary-500;
  width: 100%;
  height: 100%;
  padding: 0;
  @include customizableText(500, 16px, 20px);

  &--disabled {
    color: $gray-400;
  }

  &::placeholder {
    color: $gray-400;
  }

  &:focus::placeholder {
    color: transparent;
  }
}

.required {
  color: $danger-500;
  margin-left: 2px;
}

.icon-end {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
  cursor: pointer;
}

.help-text {
  position: absolute;
  top: 95%;
  left: 0;
  transform: translateY(8px);

  font-size: 12px;
  color: $gray-500;
  @include customizableText(600, 12px, 15px);

  &--error {
    color: $danger-500;
  }

  &.hidden {
    visibility: hidden;
  }
}

.show-password {
  color: $primary-500;
  cursor: pointer;
  @include customizableText(500, 14px, 18px);
}
