@import "/src/styles/scss/variables";
@import "/src/styles/scss/mixins/typography";
@import "/src/styles/scss/breakpoints";

.custom-title {
  @include customizableText(500, 24px, 30px);

  margin-top: 100px;
  margin-bottom: 20px;

  @include mobile {
    @include customizableText(500, 28px, 30px);
  }

  @include tablet {
    @include customizableText(500, 30px, 38px);
  }

  @include desktop {
    @include customizableText(500, 32px, 38px);

    margin-top: 200px;
    margin-bottom: 73px;
  }
}

.root {
  display: flex;
  flex-direction: column;

  @include customizableText(400, 14px, 20px);
  padding: 0 20px;
  margin-bottom: 100px;

  @include desktop {
    @include customizableText(400, 18px, 28px);
    text-align: justify;
    padding: 0 100px;
    margin-bottom: 180px;
  }
}

.text {
  > p {
    > span {
      > span {
      font-weight: 500;
      color: $primary;
      }
    }
    position: relative;
    &:not(.text_p){
      margin-bottom: 93px;
    &::after {
      content: "";
      position: absolute;
      bottom: -46px;
      left: 0;
      width: 16px;
      height: 1px;
      background-color: black;
      }
    }
  }
}

.text_p {
  margin-bottom: 73px;
}


.email-link {
  color: $primary;

  margin: 0 5px;

  text-decoration: underline !important;
}

.title {

  @include customizableText(500, 18px, 20px);
  align-self: flex-start;
  margin-bottom: 20px;

  @include tablet {
    @include customizableText(500, 20px, 21px);
  }

  @include desktop {
    @include customizableText(500, 24px, 28px);
    margin-bottom: 25px;
  }
}

.strong-text {
  font-weight: 500;

  margin: 0 5px;
}

.list {
  margin-top: 25px;
  margin-bottom: 180px;
  padding-left: 30px;
  :nth-child(3) {
    font-weight: 500;
  }
}

.item_link {
  color: $primary;
}

.seo {
  position: absolute;
  left: -9999px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}
